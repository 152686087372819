<!-- src/views/Unauthorized.vue -->
<template>
    <div class="unauthorized">
      <h2>Accès refusé</h2>
      <p>Vous n'avez pas les autorisations nécessaires pour accéder à cette page.</p>
      <router-link to="/dashboard">Retour à l'accueil</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UnauthorizedView',
  };
  </script>
  
  <style scoped>
  .unauthorized {
    text-align: center;
    padding: 2rem;
  }
  </style>
  