<template>
  <div class="flex h-screen bg-gray-900 text-white">
    <!-- Sidebar -->
    <div class="sidebar w-1/5 bg-gray-800 p-6 flex flex-col">
      <div class="title font-semibold text-xl mb-8">Tableau de bord</div>
      <div class="mb-4">
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">dashboard</span>
          Advanced Paste
        </button>
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">light_mode</span>
          Awake
        </button>
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">palette</span>
          Color Picker
        </button>
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">settings</span>
          PowerToys Run
        </button>
      </div>
      <div class="font-semibold text-sm mt-8 mb-4">Outils système</div>
      <div class="mb-4">
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">cut</span>
          Rogner et verrouiller
        </button>
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">compare_arrows</span>
          FancyZones
        </button>
      </div>
    </div>
    <!-- Content Area -->
    <div class="flex-1 flex flex-col p-8">
      <div class="title font-semibold text-3xl mb-8">Tableau de bord</div>
      <div class="font-semibold text-xl mb-4">Modules activés</div>
      <div class="grid grid-cols-2 gap-6">
        <div class="bg-gray-800 p-6 rounded-lg flex flex-col gap-3">
          <div class="font-semibold text-lg">Advanced Paste</div>
          <div class="text-gray-400 text-sm">Shift + V &nbsp; | &nbsp; Ouvrir la fenêtre Advanced Paste</div>
          <div class="flex justify-between items-center">
            <span class="text-gray-400 text-sm">Ctrl + Alt + V &nbsp; | &nbsp; Coller directement en texte brut</span>
            <div class="toggle-container bg-gray-600 rounded-full w-12 h-6 flex items-center cursor-pointer">
              <div class="toggle-circle w-5 h-5 bg-blue-500 rounded-full ml-7"></div>
            </div>
          </div>
        </div>
        <div class="bg-gray-800 p-6 rounded-lg flex flex-col gap-3">
          <div class="font-semibold text-lg">Always On Top</div>
          <div class="text-gray-400 text-sm">Ctrl + T &nbsp; | &nbsp; Épingler une fenêtre</div>
          <div class="flex justify-between items-center">
            <span class="text-gray-400 text-sm">Garde votre PC actif</span>
            <div class="toggle-container bg-gray-600 rounded-full w-12 h-6 flex items-center cursor-pointer">
              <div class="toggle-circle w-5 h-5 bg-blue-500 rounded-full ml-7"></div>
            </div>
          </div>
        </div>
        <!-- Additional Module Panels -->
        <!-- Copy and edit more panels as needed to fill the content -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestPage'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Segoe UI', sans-serif;
  background-color: #121212;
}

.toggle-container {
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-circle {
  transition: all 0.3s ease;
}

.toggle-container:hover .toggle-circle {
  background-color: #005ea2;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
  }
}
</style>