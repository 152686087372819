// src/api/authService.js
import { supabase } from '../supabase';

// Inscription d'un nouvel utilisateur
export const signUp = async ({ email, password, firstName, lastName, role = 'Dessinateur' }) => {
  const { data: signUpData, error: signUpError } = await supabase.auth.signUp({ email, password });
  if (signUpError) throw signUpError;

  // Récupère l'utilisateur après inscription
  const user = signUpData.user;
  if (!user) throw new Error("Erreur : utilisateur non créé.");

  // Crée un profil utilisateur dans la table profiles
  const { error: profileError } = await supabase
    .from('profiles')
    .insert([{ id: user.id, email, firstName, lastName, role }]); // Ajout du prénom, nom, email, et rôle

  if (profileError) throw profileError;
  return user;
};

// Connexion d'un utilisateur
export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) throw error;
  return data.user;
};

// Déconnexion de l'utilisateur
export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

// Réinitialisation du mot de passe
export const resetPassword = async (email) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: 'https://kung-gestion.com/update-password', // Assurez-vous que cette URL est correcte
  });
  if (error) throw error;
  return data;
};