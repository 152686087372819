<template>
  <div class="flex flex-col h-screen bg-gray-900 text-white p-8">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-3xl font-semibold">Mes Projets</h2>
      <button @click="showCreateProjectForm = true" class="flex items-center bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-500 transition duration-300">
        <span class="material-icons mr-2">add</span>Nouveau Projet
      </button>
    </div>
    <Modal :visible="showCreateProjectForm" @close="showCreateProjectForm = false">
      <h3 class="text-2xl font-semibold mb-6">Créer un Nouveau Projet</h3>
      <form @submit.prevent="handleCreateProject" class="space-y-4">
        <input v-model="newProject.title" type="text" placeholder="Titre du projet" required class="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600" />
        <textarea v-model="newProject.description" placeholder="Description" class="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600"></textarea>
        <button type="submit" class="w-full bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-500 transition duration-300">Créer</button>
      </form>
    </Modal>

    <div class="flex flex-col space-y-6 mt-6">
      <div v-for="project in projects" :key="project.id" class="bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 class="text-2xl font-semibold mb-2">{{ project.title }}</h3>
        <p class="text-gray-400">{{ project.description }}</p>
      </div>
    </div>

    <router-link to="/dashboard" class="mt-8 inline-block bg-gray-700 text-white px-6 py-3 rounded-lg hover:bg-gray-600 transition duration-300">Retour au Dashboard</router-link>
  </div>
</template>
  
<script>
  import { createProject, getUserProjects } from '../api/projectService';
  import Modal from '../components/PopupModal.vue';
  
  export default {
    components: {
      Modal,
    },
    data() {
      return {
        projects: [],
        newProject: {
          title: '',
          description: '',
        },
        showCreateProjectForm: false,
      };
    },
    async created() {
      this.projects = await getUserProjects();
    },
    methods: {
      async handleCreateProject() {
        try {
          const project = await createProject(this.newProject.title, this.newProject.description);
          if (project && project.length > 0) {
            this.projects.push(project[0]);
          }
          this.showCreateProjectForm = false;
          this.newProject = { title: '', description: '' };
        } catch (error) {
          console.error('Erreur lors de la création du projet:', error.message);
        }
      },
    },
  };
  </script>
  