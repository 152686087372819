<template>
  <div class="flex h-screen items-start justify-center bg-gray-900 text-white pt-20">
    <div class="form-container bg-gray-800 p-8 rounded-lg shadow-2xl w-full max-w-md">
      <div class="tabs-container mb-8 p-1 flex justify-center">
        <div class="tabs inline-flex rounded-md bg-gray-700 ">
          <button :class="{ 'bg-slate-800 text-gray-900': activeTab === 'login', 'bg-transparent text-white': activeTab !== 'login' }" @click="setActiveTab('login')" class="w-1/2 text-center py-2 px-4 rounded-md transition duration-300">Connexion</button> 
          <button :class="{ 'bg-slate-800 text-gray-900': activeTab === 'signup', 'bg-transparent text-white': activeTab !== 'signup' }" @click="setActiveTab('signup')" class="w-1/2 text-center py-2 px-4 rounded-md transition duration-300">Inscription</button>
        </div>
      </div>
      <div v-show="activeTab === 'login'" class="transition-opacity duration-300" :class="{ 'opacity-100': activeTab === 'login', 'opacity-0 h-0': activeTab !== 'login' }">
        <h2 class="text-3xl font-bold mb-6">Connexion</h2>
        <form @submit.prevent="handleLogin">
          <div class="form-group mb-6">
            <label for="email" class="block text-sm font-semibold mb-2">Email</label>
            <input type="email" id="email" v-model="email" placeholder="Entrez votre email" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
          </div>
          <div class="form-group mb-6">
            <label for="password" class="block text-sm font-semibold mb-2">Mot de passe</label>
            <input type="password" id="password" v-model="password" placeholder="Entrez votre mot de passe" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
          </div>
          <button type="submit" class="w-full py-3 rounded-lg bg-blue-600 hover:bg-blue-700 transition duration-300">Se connecter</button>
        </form>
        <div class="flex justify-end mt-4">
          <button @click="redirectToResetPassword" class="text-blue-500 hover:underline">Mot de passe oublié ?</button>
        </div>
      </div>
      <div v-show="activeTab === 'signup'" class="transition-opacity duration-300" :class="{ 'opacity-100': activeTab === 'signup', 'opacity-0 h-0': activeTab !== 'signup' }">
        <h2 class="text-3xl font-bold mb-6">Inscription</h2>
        <form @submit.prevent="handleRegister">
          <div class="form-group mb-6 flex space-x-4">
            <div class="w-1/2">
              <label for="firstName" class="block text-sm font-semibold mb-2">Prénom</label>
              <input type="text" v-model="firstName" placeholder="Entrez votre prénom" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
            </div>
            <div class="w-1/2">
              <label for="lastName" class="block text-sm font-semibold mb-2">Nom</label>
              <input type="text" v-model="lastName" placeholder="Entrez votre nom" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
            </div>
          </div>
          <div class="form-group mb-6">
            <label for="email" class="block text-sm font-semibold mb-2">Email</label>
            <input type="email" v-model="email" placeholder="Entrez votre email" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
          </div>
          <div class="form-group mb-6">
            <label for="password" class="block text-sm font-semibold mb-2">Mot de passe</label>
            <input type="password" v-model="password" placeholder="Entrez votre mot de passe" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
          </div>
          <div class="form-group mb-6">
            <label for="confirmPassword" class="block text-sm font-semibold mb-2">Confirmez le mot de passe</label>
            <input type="password" v-model="confirmPassword" placeholder="Confirmez votre mot de passe" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border" :class="{ 'border-gray-600': passwordsMatch, 'border-red-600': !passwordsMatch && confirmPassword }" @input="checkPasswordsMatch" />
          </div>
          <button type="submit" class="w-full py-3 rounded-lg bg-green-600 hover:bg-green-700 transition duration-300" :disabled="!passwordsMatch">S'inscrire</button>
        </form>
      </div>
      <!-- Affichage du message de notification -->
      <p v-if="notification" class="notification mt-4">{{ notification }}</p>
    </div>
  </div>
</template>

<script>
import { signUp, signIn, resetPassword } from '../api/authService';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '', // Confirm password for signup
      firstName: '', // First name for signup
      lastName: '', // Last name for signup
      notification: '', // Variable pour stocker le message de notification
      errorMessage: '', // Message d'erreur pour afficher les erreurs
      activeTab: 'login', // Active tab state
      passwordsMatch: true, // State to indicate if passwords match
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    checkPasswordsMatch() {
      this.passwordsMatch = this.password === this.confirmPassword;
    },
    async handleLogin() {
      try {
        await signIn(this.email, this.password);
        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Erreur de connexion :', error.message);
        this.errorMessage = error.message;
      }
    },
    async handleRegister() {
      if (!this.passwordsMatch) {
        return;
      }
      try {
        await signUp({
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
        });

        // Message de notification
        this.notification = "Inscription réussie ! Veuillez vérifier votre email pour activer votre compte.";

        // Réinitialiser les champs
        this.email = '';
        this.password = '';
        this.confirmPassword = '';
        this.firstName = '';
        this.lastName = '';
      } catch (error) {
        console.error("Erreur d'inscription :", error.message);
        this.errorMessage = error.message;
      }
    },
    async redirectToResetPassword() {
      try {
        await resetPassword(this.email);
        this.notification = "Un email de réinitialisation de mot de passe a été envoyé.";
      } catch (error) {
        console.error("Erreur lors de la réinitialisation du mot de passe :", error.message);
        this.errorMessage = error.message;
        this.notification = "Merci d'indiquer votre email pour pouvoir réinitialiser votre mot de passe.";
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Segoe UI', sans-serif;
  background-color: #121212;
}

.tabs button {
  color: #fff;
  padding: 1rem;
}

.tabs-container .tabs {
  width: 100%;
  max-width: 320px;
}

.notification {
  color: #28a745;
  font-size: 1rem;
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
  }
}
</style>