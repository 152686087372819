<!-- src/views/ProjectDetails.vue -->
<template>
  <div class="project-details">
    <h2>{{ project.title }}</h2>
    <p>{{ project.description }}</p>

    <h3>Liste des commandes</h3>
    <ul>
      <li v-for="order in orders" :key="order.id">{{ order.item_name }} - {{ order.quantity }}</li>
    </ul>
  </div>
</template>

<script>
import { supabase } from '../supabase';

export default {
  data() {
    return {
      project: {},
      orders: [],
    };
  },
  async created() {
    const projectId = this.$route.params.id;
    const { data: projectData, error: projectError } = await supabase
      .from('projects')
      .select('*')
      .eq('id', projectId)
      .single();

    if (projectError) {
      console.error('Erreur lors de la récupération du projet :', projectError.message);
    } else {
      this.project = projectData;
    }

    const { data: ordersData, error: ordersError } = await supabase
      .from('orders')
      .select('*')
      .eq('project_id', projectId);

    if (ordersError) {
      console.error('Erreur lors de la récupération des commandes :', ordersError.message);
    } else {
      this.orders = ordersData;
    }
  },
};
</script>

<style scoped>
/* Styles de base */
.project-details {
  padding: 20px;
}
</style>
