import { supabase } from '../supabase'; // Ensure the correct path

export const createProject = async (title, description) => {
  // Retrieve the authenticated user
  const { data: { user }, error: authError } = await supabase.auth.getUser();
  if (authError) {
    console.error('Erreur d\'authentification:', authError.message);
    throw new Error('Erreur d\'authentification');
  }

  if (!user) {
    throw new Error('Utilisateur non authentifié');
  }

  // Insert the project into the projects table
  const { data, error } = await supabase
    .from('projects')
    .insert([{ title, description, created_by: user.id }])
    .select(); // Add .select() to get the inserted row

  if (error) {
    console.error('Erreur lors de l\'insertion du projet:', error.message);
    throw new Error('Erreur lors de la création du projet');
  }

  return data;
};

// Fonction pour récupérer tous les projets créés par l'utilisateur
export const getUserProjects = async () => {
  const { data: { user }, error: authError } = await supabase.auth.getUser();
  if (authError) throw authError;
  if (!user) throw new Error('Utilisateur non authentifié');

  const { data, error } = await supabase
    .from('projects')
    .select('*')
    .eq('created_by', user.id);

  if (error) throw error;
  return data;
};

