<template>
  <div v-if="visible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-gray-900 text-white p-8 rounded-lg shadow-lg w-96">
      <slot></slot>
      <div class="flex justify-end mt-6">
        <button @click="$emit('close')" class="bg-gray-700 text-white px-6 py-3 rounded-lg hover:bg-gray-600 transition duration-300">
          Fermer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: {
  visible: {
    type: Boolean,
    default: false,
  },
},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
</style>
