<template>
  <div class="flex h-screen bg-gray-900 text-white p-8">
    <div class="admin-panel-container w-full max-w-5xl mx-auto bg-gray-800 p-8 rounded-lg shadow-2xl">
      <h2 class="text-3xl font-bold mb-8 text-center">Panneau d'Administration</h2>
      <ul v-if="users.length" class="user-list">
        <li v-for="user in users" :key="user.id" class="user-item bg-gray-700 p-4 mb-4 rounded-lg flex items-center justify-between">
          <span class="user-email text-lg text-gray-300">{{ user.email }}</span>
          <select v-model="user.role" @change="updateRole(user)" class="role-select bg-gray-800 text-white border border-gray-600 rounded-lg px-3 py-2">
            <option v-for="role in roles" :key="role" :value="role">
              {{ role }}
            </option>
          </select>
        </li>
      </ul>
      <button @click="goToDashboard" class="btn mt-8 py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 transition duration-300 block mx-auto">Retour au Dashboard</button>
    </div>
  </div>
</template>

<script>
import { supabase } from '../supabase';

export default {
  name: 'AdminPanel',
  data() {
    return {
      users: [], // Stocke les utilisateurs avec leurs emails et rôles
      roles: ['Apprenti', 'Dessinateur', 'Ingénieur', 'Admin'], // Liste des rôles disponibles
    };
  },
  async created() {
  try {
    // Récupérer l'utilisateur actuellement connecté
    const { data: currentUserData, error: currentUserError } = await supabase.auth.getUser();
    
    if (currentUserError) {
      console.error('Erreur lors de la récupération de l\'utilisateur connecté :', currentUserError.message);
      return;
    }

    const currentUser = currentUserData.user;

    if (!currentUser) {
      console.error("Aucun utilisateur connecté trouvé.");
      return;
    }

    // Vérifier le rôle de l'utilisateur
    const { data: currentUserProfile, error: currentUserProfileError } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', currentUser.id)
      .single();

    if (currentUserProfileError) {
      console.error('Erreur lors de la récupération du rôle de l\'utilisateur courant :', currentUserProfileError.message);
      return;
    }

    if (currentUserProfile && currentUserProfile.role === 'Admin') {
      // Récupérer tous les utilisateurs via RPC
      const { data: usersData, error: profilesError } = await supabase.rpc('get_all_profiles');

      if (profilesError) {
        console.error('Erreur lors de la récupération des utilisateurs :', profilesError.message);
      } else {
        console.log('Réponse Supabase:', usersData);
        this.users = usersData;
      }
    } else {
      console.error("L'utilisateur n'a pas l'autorisation de voir tous les profils.");
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs :", error.message);
  }
},
  methods: {
    async updateRole(user) {
      try {
        const { error } = await supabase
          .from('profiles')
          .update({ role: user.role })
          .eq('id', user.id);

        if (error) throw error;

        console.log(`Rôle de l'utilisateur ${user.email} mis à jour avec succès.`);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du rôle :", error.message);
      }
    },
    goToDashboard() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');

body {
  font-family: 'Segoe UI', sans-serif;
  background-color: #121212;
}

.admin-panel-container {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  background-color: #1f2937;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.5rem;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d3748;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.user-email {
  font-size: 1rem;
  color: #cbd5e0;
  flex: 1;
}

.role-select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #4a5568;
  border-radius: 8px;
  background-color: #1a202c;
  color: #ffffff;
  flex: 0.5;
}

select:focus {
  border-color: #4299e1;
  outline: none;
}

.btn {
  display: block;
  width: fit-content;
  padding: 0.6rem 1.2rem;
  margin: 1.5rem auto 0;
  background-color: #3182ce;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #2b6cb0;
}
</style>