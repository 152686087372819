// src/supabase.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.VUE_APP_SUPABASE_ANON_KEY;
const supabaseServiceKey = process.env.VUE_APP_SERVICE_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceKey);

export const setupAuthListener = (router) => {
  console.log("setupAuthListener initialized"); // Log pour vérifier l'initialisation
  supabase.auth.onAuthStateChange((event, session) => {
    console.log("Auth state changed:", event, session); // Log pour vérifier les changements d'état
    if (router && router.currentRoute && router.currentRoute.value) {
      console.log("Current route:", router.currentRoute.value.fullPath);
      if (!session) { 
        console.log("Redirecting to login due to no session");
        router.push('/login');
      }
    }
  });
};

// Nouvelle fonction fetchUsers : récupération depuis `profiles`
export const fetchUsers = async () => {
  const { data, error } = await supabase
    .from('profiles')
    .select('id, email, role');

  if (error) {
    console.error("Error fetching users from profiles:", error);
    return [];
  }
  
  return data;
};