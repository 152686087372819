<template>
    <div class="flex h-screen bg-gray-900 text-white">
      <!-- Sidebar -->
      <div class="sidebar w-1/5 bg-gray-800 p-6 flex flex-col">
        <div class="title font-semibold text-xl mb-8">Sociétés</div>
        <div class="mb-4">
          <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
            <span class="material-icons mr-4">dashboard</span>
            Dashboard
          </button>
        </div>
      </div>
      <!-- Content Area -->
      <div class="flex-1 flex flex-col p-8">
        <h2 class="text-3xl font-bold mb-6">Liste des Sociétés</h2>
        <button @click="showCreateCompanyForm = true" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mb-6">
          Ajouter une Société
        </button>
  
        <!-- Modal pour Créer une Nouvelle Société -->
        <div v-if="showCreateCompanyForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h3 class="text-xl font-semibold mb-4">Ajouter une Société</h3>
            <form @submit.prevent="handleCreateCompany">
              <div class="form-group mb-4">
                <label for="name" class="block text-sm font-semibold mb-2">Nom de la société</label>
                <input v-model="newCompany.name" type="text" id="name" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
              </div>
              <div class="form-group mb-4">
                <label for="type" class="block text-sm font-semibold mb-2">Type de société</label>
                <select v-model="newCompany.type" id="type" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white">
                  <option value="Masonry">Entreprise de maçonnerie</option>
                  <option value="Architect">Architecte</option>
                  <option value="Owner">Maître d'ouvrage</option>
                  <option value="Engineering">Bureau d'ingénieur</option>
                </select>
              </div>
              <div class="form-group mb-4">
                <label for="address" class="block text-sm font-semibold mb-2">Adresse postale</label>
                <input v-model="newCompany.address" type="text" id="address" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
              </div>
              <div class="form-group mb-4">
                <label for="email" class="block text-sm font-semibold mb-2">Email</label>
                <input v-model="newCompany.email" type="email" id="email" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
              </div>
              <div class="form-group mb-4">
                <label for="phone" class="block text-sm font-semibold mb-2">Numéro de téléphone</label>
                <input v-model="newCompany.phone" type="tel" id="phone" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
              </div>
              <div class="flex justify-end mt-4">
                <button type="button" @click="showCreateCompanyForm = false" class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2">Annuler</button>
                <button type="submit" class="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
  
        <!-- Liste des Sociétés -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
          <div v-for="company in companies" :key="company.id" class="bg-gray-800 p-6 rounded-lg shadow-md">
            <h3 class="text-xl font-semibold">{{ company.name }}</h3>
            <p class="text-gray-400">Type : {{ company.type }}</p>
            <p class="text-gray-400">Adresse : {{ company.address }}</p>
            <p class="text-gray-400">Email : {{ company.email }}</p>
            <p class="text-gray-400">Téléphone : {{ company.phone }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { supabase } from '../supabase';
  
  export default {
    name: 'CompanyList',
    data() {
      return {
        companies: [],
        newCompany: {
          name: '',
          type: 'Masonry',
          address: '',
          email: '',
          phone: ''
        },
        showCreateCompanyForm: false,
      };
    },
    async created() {
      await this.fetchCompanies();
    },
    methods: {
      async fetchCompanies() {
        const { data, error } = await supabase.from('companies').select('*');
        if (error) {
          console.error('Erreur lors de la récupération des sociétés :', error.message);
        } else {
          this.companies = data;
        }
      },
      async handleCreateCompany() {
        const { data, error } = await supabase.from('companies').insert([this.newCompany]);
        if (error) {
          console.error('Erreur lors de la création de la société :', error.message);
        } else {
          this.companies.push(data[0]);
          this.showCreateCompanyForm = false;
          this.newCompany = { name: '', type: 'Masonry', address: '', email: '', phone: '' };
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');
  
  body {
    font-family: 'Segoe UI', sans-serif;
    background-color: #121212;
  }
  
  .sidebar {
    max-width: 300px;
  }
  
  button {
    display: flex;
    align-items: center;
  }
  </style>  