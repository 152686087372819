// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/Login.vue';
import DashboardView from '../views/Dashboard.vue';
import ProjectList from '../views/ProjectList.vue';
import ProjectDetails from '../views/ProjectDetails.vue';
import AdminPanel from '../views/AdminPanel.vue';
import TestPage from '../views/TestPage.vue';
import UnauthorizedView from '../views/Unauthorized.vue';
import UpdatePassword from '../views/UpdatePassword.vue';
import CompanyList from '../views/CompanyList.vue';
import { supabase } from '../supabase';

const routes = [
  { path: '/login', name: 'Login', component: LoginView },
  { path: '/dashboard', name: 'Dashboard', component: DashboardView, meta: { requiresAuth: true }},
  { path: '/projects', name: 'ProjectList', component: ProjectList, meta: { requiresAuth: true } },
  { path: '/projects/:id', name: 'ProjectDetails', component: ProjectDetails, meta: { requiresAuth: true }},
  { path: '/admin', name: 'AdminPanel', component: AdminPanel, meta: { requiresAuth: true, requiresAdmin: true }},
  { path: '/unauthorized', name: 'UnauthorizedView', component: UnauthorizedView },
  { path: '/test', name: 'TestPage', component: TestPage },
  { path: '/update-password', name: 'UpdatePassword', component: UpdatePassword },
  { path: '/companies', name: 'CompanyList', component: CompanyList, meta: { requiresAuth: true }},
  { path: '/:pathMatch(.*)*', redirect: '/login' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Guard pour vérifier si l'utilisateur est Admin
router.beforeEach(async (to, from, next) => {
  // Exclure la page de mise à jour du mot de passe de la redirection
  if (to.path.startsWith('/update-password')) {
    return next();
  }
  const { data: { user } } = await supabase.auth.getUser();
  const isAuthenticated = !!user;

  // Vérifie si la route nécessite une authentification
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    return next('/login');
  }

  // Vérifie si la route nécessite un rôle d'admin
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const { data, error } = await supabase
      .from('user_roles')
      .select('role')
      .eq('id', user?.id)
      .single();

    if (error || data.role !== 'Admin') {
      return next('/unauthorized');
    }
  }

  next(); // Autorise l'accès si tout est en règle
});

export default router;
