<template>
    <div class="flex h-screen items-center justify-center bg-gray-900 text-white p-8">
      <div class="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 class="text-3xl font-semibold mb-6">Mise à jour du mot de passe</h2>
        <form @submit.prevent="handleUpdatePassword" class="space-y-4">
          <div class="form-group mb-6">
            <label for="newPassword" class="block text-sm font-semibold mb-2">Nouveau mot de passe</label>
            <input type="password" v-model="newPassword" placeholder="Entrez votre nouveau mot de passe" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
          </div>
          <div class="form-group mb-6">
            <label for="confirmPassword" class="block text-sm font-semibold mb-2">Confirmez le nouveau mot de passe</label>
            <input type="password" v-model="confirmPassword" placeholder="Confirmez votre nouveau mot de passe" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border" :class="{ 'border-gray-600': passwordsMatch, 'border-red-600': !passwordsMatch && confirmPassword }" @input="checkPasswordsMatch" />
          </div>
          <button type="submit" class="w-full py-3 rounded-lg bg-green-600 hover:bg-green-700 transition duration-300" :disabled="!passwordsMatch">Mettre à jour le mot de passe</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { supabase } from '../supabase';
  
  export default {
    name: 'UpdatePassword',
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        passwordsMatch: true,
      };
    },
    async mounted() {
      // Récupérer le token d'accès depuis l'URL
      const accessToken = this.$route.query.access_token;
      if (accessToken) {
        // Authentifier l'utilisateur avec le token d'accès
        const { error } = await supabase.auth.setSession({ access_token: accessToken });
        if (error) {
          console.error("Erreur lors de la récupération de la session :", error.message);
          this.$router.push('/login');
        }
      } else {
        // Si pas de token, rediriger vers la page de connexion
        this.$router.push('/login');
      }
    },
    methods: {
      checkPasswordsMatch() {
        this.passwordsMatch = this.newPassword === this.confirmPassword;
      },
      async handleUpdatePassword() {
        try {
          if (!this.passwordsMatch) {
            return;
          }
          const { error } = await supabase.auth.updateUser({ password: this.newPassword });
          if (error) throw error;
          this.$router.push('/login');
        } catch (error) {
          console.error("Erreur lors de la mise à jour du mot de passe:", error.message);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');
  
  body {
    font-family: 'Segoe UI', sans-serif;
    background-color: #121212;
  }
  </style>