// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Assure-toi que c'est bien importé par défaut
import { setupAuthListener } from './supabase';
import './assets/tailwind.css';

const app = createApp(App);
setupAuthListener(router);

app.use(router).mount('#app');
