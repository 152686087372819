// src/views/Dashboard.vue
<template>
  <div class="flex h-screen bg-gray-900 text-white">
    <!-- Sidebar -->
    <div class="sidebar w-1/5 bg-gray-800 p-6 flex flex-col">
      <div class="title font-semibold text-xl mb-8">Tableau de bord</div>
      <div class="mb-4">
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">dashboard</span>
          Mes Projets
        </button>
        <button class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">business</span>
          Sociétés
        </button>
        <button v-if="isAdmin" class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2">
          <span class="material-icons mr-4">admin_panel_settings</span>
          Panneau d'administration
        </button>
      </div>
      <button @click="handleLogout" class="w-full flex items-center px-4 py-3 rounded-lg bg-red-600 hover:bg-red-500 transition duration-300 mt-auto">
        <span class="material-icons mr-4">logout</span>
        Se déconnecter
      </button>
    </div>
    <!-- Content Area -->
    <div class="flex-1 flex flex-col p-8">
      <div class="title font-semibold text-3xl mb-8">Bienvenue sur le Dashboard</div>
      <div class="font-semibold text-xl mb-4">Modules activés</div>
      <div class="grid grid-cols-2 gap-6">
        <div class="bg-gray-800 p-6 rounded-lg flex flex-col gap-3">
          <div class="font-semibold text-lg">Mes Projets</div>
          <router-link to="/projects" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">Voir mes projets</router-link>
        </div>
        <div class="bg-gray-800 p-6 rounded-lg flex flex-col gap-3">
          <div class="font-semibold text-lg">Sociétés</div>
          <router-link to="/companies" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">Voir les sociétés</router-link>
        </div>
        <div v-if="isAdmin" class="bg-gray-800 p-6 rounded-lg flex flex-col gap-3">
          <div class="font-semibold text-lg">Panneau d'administration</div>
          <router-link to="/admin" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">Accéder au panneau d'administration</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '../supabase';

export default {
  name: 'DashboardView',
  data() {
    return {
      isAdmin: false,
    };
  },
  async created() {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
      console.error(userError.message);
      return;
    }

    const { data, error } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', user.id)
      .single();

    if (error) {
      console.error(error.message);
    } else if (data.role === 'Admin') {
      this.isAdmin = true;
    }
  },
  methods: {
    async handleLogout() {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Erreur lors de la déconnexion:', error.message);
      } else {
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Segoe UI', sans-serif;
  background-color: #121212;
}

.sidebar {
  max-width: 300px;
}

button {
  display: flex;
  align-items: center;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
  }
}
</style>